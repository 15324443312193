.main {
  background-color: rgba(244, 249, 253, 1);
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
}

.header {
  position: absolute;
  top: 0;
  padding: 40px 3%;
}

.paper_left {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-left: 15%;
  padding-top: 30px;
  background-color: #f5f5f5 !important;
}
.rightsReserved {
  position: absolute;
  bottom: 40px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #7c8794;
}
.verticalStepLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
}
.form {
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: white;
}

.headerMargin {
  margin-bottom: 80px;
}
.signInLink {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.288889px;
  color: #767676;
}

.signInLink a {
  color: #133fe3;
}
.paper_right_data {
  overflow: scroll;
  padding: 30px 10%;
  height: calc(100vh - 86px);
}

.paper_right_bottom {
  position: absolute;
  background-color: white;
  bottom: 0;
  height: 86px;
}

.paper_right_btn {
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
}

.formTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -1.15556px;
  text-transform: capitalize;
  color: #1a1a1a;
}

.formText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.288889px;
  color: #767676;
  margin-bottom: 5%;
}

.prevBtn {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: #767676 !important;
  text-transform: capitalize !important;
}

.navigation {
  font-family: "Inter" !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  padding: 18px 10% !important;
}

.title2 {
  font-family: 'Inter';
  font-style: 'normal';
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.tagline {
  color: #767676 !important;
  font-family: 'Inter';
  font-weight: '400';
  font-style: 'normal';
  font-size: 14px !important;
}




