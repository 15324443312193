@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Urbanist:wght@400;500;700&display=swap");

/* font-family: 'Urbanist', sans-serif; */

:root {
  --primary-font: "Inter", sans-serif;
}

:root {
  --themeColor: rgba(19, 63, 227, 1);
}

body {
  font-family: var(--primary-font);
}
.labelForm {
  font-size: 14px;

  color: #000000;

  line-height: 22px;
}

.formLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-bottom: 10px;
}

.MuiStepConnector-root {
  margin-left: 10px !important;
}

.MuiStepConnector-line {
  border-style: solid !important;
  border-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.24)
    )
    1 100% !important;

  border-left-style: solid;
  border-left-width: 3px !important;
  min-height: 79px !important;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.MuiOutlinedInput-root:hover fieldset {
  border-color: var(--themeColor) !important;
}

/* .MuiInputBase-root:focus-visible{
  outline-color: var(--themeColor) !important;
} */

.MuiTab-root.Mui-selected {
  color: #000022 !important;
  font-weight: 700;
}

.btn_delete {
  font-size: 12px;
  text-transform: capitalize;
  color: rgba(255, 0, 0, 0.5) !important;
  margin: 0 20px;
}

.btn_delete:hover {
  color: rgba(255, 0, 0, 0.7) !important;
}

.btn_addMore {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: capitalize !important;
  color: #214be4 !important;
  padding: 8px 13px !important;
  border: 1px solid #214be4 !important;
  border-radius: 8px !important;
}

.btn_add {
  background: #133fe3 !important;
  color: white !important;
  border-radius: 8px !important;
  padding: 8px 40px !important;
}

.btn_cancel {
  color: #133fe3 !important;
  background: white !important;
  border-radius: 8px !important;
  padding: 8px 30px !important;
  border: 1px solid #133fe3 !important;
}

.btn_delete {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 18px;
  color: #ff0c0c !important;
  text-transform: capitalize !important;
}

.modal_heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}


.dial.muirtl-wb57ya-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 9px !important;
  text-align: center !important;
}


.react-datepicker.react-datepicker--time-only, .react-datepicker--time-only .react-datepicker__time-container{
  width: 120px !important;
}

.react-datepicker__time-container{
  border: 1px solid #D0D5DD !important;
  border-radius: 8px !important; 
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select){
  display: none !important;
}


/* .react-datepicker__time-container .react-datepicker__time {
  width: 120px;
  background: #D0D5DD;
  border-radius: 8px;
} */