.main {
  min-height: 100vh;
}
.right {
  background-color: #ecf2ff;
  min-height: 100vh;
}
.left {
  padding: 5%;
  overflow: scroll;
  max-height: 100vh;
}
.title {
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -1.15556px;
  text-transform: capitalize;
  color: #1a1a1a;
  margin-bottom: 10px;
}
.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.288889px;
  color: #767676;
  margin-bottom: 35px;
}

.main label {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #979797;
}
.rememberMe {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #7c8794;
}
.forgotLink {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-decoration: underline !important;
  color: #3c434a !important;
}
.createAccount {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.288889px;
  color: #767676;
  text-align: center;
  margin-top: 30px;
}

.createAccount a {
  text-decoration: none !important;
}
.rightsReserved {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #7c8794;
}
