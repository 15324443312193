.main {
  min-height: 100vh;
  position: relative;
}
.backGround {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.header {
  padding: 2%;
  position: absolute;
  top: 0;
}
.btn_signUp {
  border-radius: 3px !important;
  padding: 8px 40px !important;
  text-transform: capitalize !important;
}
.btn_logIn {
  border-radius: 3px !important;
  margin: 8px 30px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
}
.right {
  background-color: #ecf2ff;
  min-height: 100vh;
}
.left {
  padding: 3%;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 74px;
  letter-spacing: -1.15556px;
  text-transform: capitalize;
  color: #1a1a1a;
  margin-bottom: 20px;
}
.text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140.62%;
  max-width: 500px;
  text-align: justify;
  color: #767676;
  margin-bottom: 50px;
}
.btn_started {
  padding: 13px 12% !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
}
